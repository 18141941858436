import OrderIcon from '@mui/icons-material/AttachMoney';

import OrderList from './OrderList';
import OrderEdit from './OrderEdit';

const _default= {
    list: OrderList,
    edit: OrderEdit,
    icon: OrderIcon,
};
export default _default