

import VisitorList from './VisitorList';
import VisitorCreate from './VisitorCreate';
import VisitorEdit from './VisitorEdit';
import {WidgetsOutlined} from "@mui/icons-material";

const resource = {
    list: VisitorList,
    create: VisitorCreate,
    edit: VisitorEdit,
    icon: WidgetsOutlined,
};

export default resource;
