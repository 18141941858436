import {
    DatagridConfigurable,
    DateField,
    Edit,
    ExportButton,
    List,
    MenuItemLink,
    SelectColumnsButton,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TopToolbar,
    useTranslate
} from 'react-admin';
import {Box, Card} from "@mui/material";
import * as React from "react";

import {RttOutlined} from "@mui/icons-material"
import {LAMenuItemProps} from "../../types";


const ListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <ExportButton />
    </TopToolbar>
);

export const list = () => (
    <List
        actions={<ListActions />}
    >
        <DatagridConfigurable rowClick="show">
            <TextField source="id" />
            <DateField source="time" showTime={true} />
            <TextField source="blocksCount" />
            <TextField source="msgCount" />
            <TextField source="baseFee" />
        </DatagridConfigurable>
    </List>
);

export const show = () => (

        <Card sx={{ maxWidth: '50em' }} >
            <h2>Epoch</h2>
            <Show>
                    <SimpleShowLayout >

                            <TextField source="id" />
                            <DateField source="time" />
                            {/*<TextField source="blocks" />*/}
                            {/*<TextField source="totalMsg" />*/}
                            <TextField source="baseFee" />

                    </SimpleShowLayout>
            </Show>
        </Card>

);

export const edit = () => (
    <Box sx={{ maxWidth: '50em' }}>
        <Card>
            <Edit >
                <SimpleForm>
                            <TextField source="id" />
                            <DateField source="time" />
                            {/*<TextField source="blocks" />*/}
                            {/*<TextField source="totalMsg" />*/}
                            <TextField source="baseFee" />
                </SimpleForm>
            </Edit>
        </Card>
    </Box>

);

// export type LAMenuItemProps=Omit<MenuItemLinkProps, 'to'>
const LaMenuItem = (props: LAMenuItemProps ) => {
    const translate=useTranslate()
    return (
        <MenuItemLink
            to="/Epoch"
            state={{_scrollToTop: true}}
            primaryText={translate(`resources.blockchain.epochs`, {
                smart_count: 2,
            })}
            leftIcon={<RttOutlined/>}
            dense={false}
        />
    );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {list,show, icon:RttOutlined ,LaMenuItem}