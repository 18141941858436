import {Datagrid, List, MenuItemLink, TextField, useTranslate} from "react-admin";
import MessageIcon from '@mui/icons-material/Message';
import {LAMenuItemProps} from "../../types";
import * as React from "react";
import {ShortUUIDField} from "../../fields";

export const list = () => (
    <List>
        <Datagrid rowClick="show">
            <TextField source="epoch" />
            <ShortUUIDField source="from"  side="both" />
            <TextField source="to" />
            <TextField source="method" />
            <TextField source="nonce" />
            <TextField source="Amount" />
        </Datagrid>
    </List>
);

const LaMenuItem = (props: LAMenuItemProps ) => {
    const translate=useTranslate()
    return (
        <MenuItemLink
            to="/Message"
            state={{_scrollToTop: true}}
            primaryText={translate(`resources.blockchain.messages`, {
                smart_count: 2,
            })}
            leftIcon={<MessageIcon/>}
            dense={false}
        />
    );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {list,icon:MessageIcon,name:'Message',LaMenuItem}