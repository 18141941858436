import {Show, SimpleShowLayout, TextField} from "react-admin";
import {Card} from "@mui/material";
import * as React from "react";


export const show = () => (
<Card sx={{ maxWidth: '50em' }} >
    <h2>Tenant</h2>
    <Show>
        <SimpleShowLayout >
            <TextField source="id" />
            <TextField source="name" />

        </SimpleShowLayout>
    </Show>
</Card>
)
// eslint-disable-next-line import/no-anonymous-default-export
export default {show}