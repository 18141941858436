
import ProductList from './ProductList';
import ProductEdit from './ProductEdit';
import ProductCreate from './ProductCreate';
import {FolderCopyOutlined} from "@mui/icons-material";
import {DocumentScannerOutlined} from "@mui/icons-material"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: ProductList,
    create: ProductCreate,
    edit: ProductEdit,
    icon: FolderCopyOutlined,
    minerStateIcon:DocumentScannerOutlined
};
