import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';

import {DashboardMenuItem, MenuProps, useSidebarState,} from 'react-admin';

import visitors from '../visitors';
import orders from '../orders';
import products from '../products';
import SubMenu from './SubMenu';
import Epoch from '../entity/epoch'
import Block from "../entity/block";
import Account from "../entity/account";
import StorageDeal from "../entity/storage-deals";
import Message from "../entity/message";
import DealProposal from "../entity/deal-proposals";
import Sector from "../entity/sector"
import Partition from "../entity/sector/miner_partition"
import SpLedgerEntry from "../entity/sp-ledger-entry";

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers' | 'menuBlockchain' | 'menuMarket' | 'menuMiner';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
        menuBlockchain:true,
        menuMarket:true,
        menuMiner:true
    });
    //const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    //type LAMenuItemProps=Omit<MenuItemLinkProps, 'to'>// & {LAComponent:any}
    // const MenuItem_Epoch = (props: LAMenuItemProps ) => {
    //
    //     return (
    //         <MenuItemLink
    //             to="/Epoch"
    //             state={{_scrollToTop: true}}
    //             primaryText={translate(`resources.blockchain.epochs`, {
    //                 smart_count: 2,
    //             })}
    //             leftIcon={<Epoch.icon/>}
    //             dense={dense}
    //         />
    //     );
    // };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem  />
            <SubMenu
                handleToggle={() => handleToggle('menuMarket')}
                isOpen={state.menuMarket}
                name="pos.menu.market"
                icon={<orders.icon />}
                dense={dense}
            >
                <StorageDeal.LaMenuItem/>
                <DealProposal.LaMenuItem/>
                {/*<MenuItemLink*/}
                {/*    to="/commands"*/}
                {/*    state={{ _scrollToTop: true }}*/}
                {/*    primaryText={translate(`resources.proposals.name`, {*/}
                {/*        smart_count: 2,*/}
                {/*    })}*/}
                {/*    leftIcon={<StorageDeal.icon />}*/}
                {/*    dense={dense}*/}
                {/*/>*/}
                {/*<MenuItemLink*/}
                {/*    to="/invoices"*/}
                {/*    state={{ _scrollToTop: true }}*/}
                {/*    primaryText={translate(`resources.deals.name`, {*/}
                {/*        smart_count: 2,*/}
                {/*    })}*/}
                {/*    leftIcon={<invoices.icon />}*/}
                {/*    dense={dense}*/}
                {/*/>*/}
            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle('menuMiner')}
                isOpen={state.menuMiner}
                name="pos.menu.miner"
                icon={<products.minerStateIcon />}
                dense={dense}
            >
                <Partition.LaMenuItem/>
                <Sector.LaMenuItem/>
                <SpLedgerEntry.LaMenuItem/>


            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuBlockchain')}
                isOpen={state.menuBlockchain}
                name="pos.menu.blockchain"
                icon={<visitors.icon />}
                dense={dense}
            >
                <Epoch.LaMenuItem/>
                <Block.LaMenuItem/>
                <Account.LaMenuItem/>
                <Message.LaMenuItem/>


            </SubMenu>

        </Box>
    );
};

export default Menu;
