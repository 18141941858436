import * as React from 'react';
import {AppBar, Logout, UserMenu, useTranslate} from 'react-admin';
import {Link} from 'react-router-dom';
import {ListItemIcon, ListItemText, MenuItem, Typography,} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';


const ConfigurationMenu = React.forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItem
            component={Link}
            // @ts-ignore
            ref={ref}
            {...props}
            to="/configuration"
        >
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText>{translate('pos.configuration')}</ListItemText>
        </MenuItem>
    );
});
const CustomUserMenu = () => (
    <UserMenu>
        <ConfigurationMenu />
        <Logout />
    </UserMenu>
);

const CustomAppBar = (props: any) => {
    // const isLargeEnough = useMediaQuery<Theme>(theme =>
    //     theme.breakpoints.up('sm')
    // );
    return (
        <AppBar
            {...props}
            color="secondary"
            elevation={1}
            userMenu={<CustomUserMenu />}
        >
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
                id="react-admin-title"
            />
            {/*{isLargeEnough && <Logo />}*/}
            {/*{isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}*/}
        </AppBar>
    );
};

export default CustomAppBar;
