import * as React from 'react';
import {Card, CardContent} from '@mui/material';
import MailIcon from '@mui/icons-material/MailOutline';
import {FilterList, FilterListItem,} from 'react-admin';


const Aside = () => (
    <Card
        sx={{
            display: {
                xs: 'none',
                md: 'block',
            },
            order: -1,
            flex: '0 0 15em',
            mr: 2,
            mt: 8,
            alignSelf: 'flex-start',
        }}
    >
        <CardContent sx={{ pt: 1 }}>
            {/*<FilterLiveSearch />*/}

            {/*<SavedQueriesList />*/}

            {/*<FilterList*/}
            {/*    label="resources.customers.filters.last_visited"*/}
            {/*    icon={<AccessTimeIcon />}*/}
            {/*>*/}
            {/*    <FilterListItem*/}
            {/*        label="resources.customers.filters.today"*/}
            {/*        value={{*/}
            {/*            last_seen_gte: endOfYesterday().toISOString(),*/}
            {/*            last_seen_lte: undefined,*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    <FilterListItem*/}
            {/*        label="resources.customers.filters.this_week"*/}
            {/*        value={{*/}
            {/*            last_seen_gte: startOfWeek(new Date()).toISOString(),*/}
            {/*            last_seen_lte: undefined,*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    <FilterListItem*/}
            {/*        label="resources.customers.filters.last_week"*/}
            {/*        value={{*/}
            {/*            last_seen_gte: subWeeks(*/}
            {/*                startOfWeek(new Date()),*/}
            {/*                1*/}
            {/*            ).toISOString(),*/}
            {/*            last_seen_lte: startOfWeek(new Date()).toISOString(),*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    <FilterListItem*/}
            {/*        label="resources.customers.filters.this_month"*/}
            {/*        value={{*/}
            {/*            last_seen_gte: startOfMonth(new Date()).toISOString(),*/}
            {/*            last_seen_lte: undefined,*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    <FilterListItem*/}
            {/*        label="resources.customers.filters.last_month"*/}
            {/*        value={{*/}
            {/*            last_seen_gte: subMonths(*/}
            {/*                startOfMonth(new Date()),*/}
            {/*                1*/}
            {/*            ).toISOString(),*/}
            {/*            last_seen_lte: startOfMonth(new Date()).toISOString(),*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    <FilterListItem*/}
            {/*        label="resources.customers.filters.earlier"*/}
            {/*        value={{*/}
            {/*            last_seen_gte: undefined,*/}
            {/*            last_seen_lte: subMonths(*/}
            {/*                startOfMonth(new Date()),*/}
            {/*                1*/}
            {/*            ).toISOString(),*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</FilterList>*/}

            {/*<FilterList*/}
            {/*    label="resources.customers.filters.has_ordered"*/}
            {/*    icon={<MonetizationOnIcon />}*/}
            {/*>*/}
            {/*    <FilterListItem*/}
            {/*        label="ra.boolean.true"*/}
            {/*        value={{*/}
            {/*            nb_commands_gte: 1,*/}
            {/*            nb_commands_lte: undefined,*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    <FilterListItem*/}
            {/*        label="ra.boolean.false"*/}
            {/*        value={{*/}
            {/*            nb_commands_gte: undefined,*/}
            {/*            nb_commands_lte: 0,*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</FilterList>*/}

            <FilterList
                label="Deals"
                icon={<MailIcon />}
            >
                <FilterListItem
                    label="with deals"
                    value={{ hasDeals: true }}
                />
                <FilterListItem
                    label="without deals"
                    value={{ hasDeals: false }}
                />
            </FilterList>
            <FilterList
                label="Sector status"
                icon={<MailIcon />}
            >
                <FilterListItem
                    label="Faulty"
                    value={{ faulty: true }}
                />
                <FilterListItem
                    label="Recovering"
                    value={{ recovering: true }}
                />

            </FilterList>

            <FilterList
                label="Sector active"
                icon={<MailIcon />}
            >
                <FilterListItem
                    label="active"
                    value={{ active: true }}

                />
                <FilterListItem
                    label="non active"
                    value={{ active: false }}

                />
            </FilterList>
            {/*<FilterList*/}
            {/*    label="resources.customers.filters.group"*/}
            {/*    icon={<LocalOfferIcon />}*/}
            {/*>*/}
            {/*    {segments.map(segment => (*/}
            {/*        <FilterListItem*/}
            {/*            label={segment.name}*/}
            {/*            key={segment.id}*/}
            {/*            value={{ groups: segment.id }}*/}
            {/*        />*/}
            {/*    ))}*/}
            {/*</FilterList>*/}
        </CardContent>
    </Card>
);

export default Aside;
