import {Show, SimpleShowLayout, TextField, useRecordContext} from "react-admin";
import {Grid, Paper} from "@mui/material";
import {styled} from "@mui/material/styles";
import {FilAmountField, FileSizeField} from "../../widget";
import {ActorType} from "../../utils/types";
import MinerDashboard from "./miner_dashboard"
//1A2027
const Item = styled(Paper)(({ style,theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:'100%'

}));
export const show=()=>{
    return <Show><ShowDispatch/></Show>
}
export const ShowDispatch =() => {

    const rec=useRecordContext()
    if (!rec)return null
    console.log({rec})
    const acc_type=Number(rec.account_type)
    switch (acc_type){
        case ActorType.miner:
            return <MinerDashboard/>
        default:
            return <Show1/>
    }
}
export const Show1 = () => {

    return  (

            <SimpleShowLayout>
                <TextField source="id"/>
                <Grid container>
                    <TextField source="id" label={true}/>
                    {/*<ReferenceField source="tenant_id" reference="Tenant" />*/}
                    {/*<TextField source="Address" />*/}

                    {/*<ReferenceField source="Owner" reference="Account" />*/}
                    {/*<ReferenceField source="Worker" reference="Account" />*/}
                    {/*<ReferenceField source="Beneficiary" reference="Account" />*/}
                    {/*<ReferenceArrayField source="ControlAddresses" reference="Account"/>*/}
                    {/*<TextField source="name" />*/}
                    {/*<TextField source="descr" />*/}
                    {/*<TextField source="robust_addr" />*/}
                    {/*<TextField source="sealProof" />*/}
                    {/*<SelectField source="account_type" choices={[*/}
                    {/*    { id: 0, name: 'Miner' },*/}
                    {/*    { id: 1, name: 'Account 1' },*/}
                    {/*    { id: 2, name: 'Account 2' },*/}
                    {/*    { id: 3, name: 'Account 3' },*/}
                    {/*]} />*/}

                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Item>
                            <h3>Balance data:</h3>
                            <FilAmountField source="Balance"/>

                        </Item>
                    </Grid>
                    <Grid item xs={3}>
                        <Item>
                            <h3>Power data</h3>
                            <FileSizeField source="qap"/>
                            <p/>
                            <FileSizeField source="rbp"/>
                        </Item>
                    </Grid>
                    <Grid item xs={3}>
                        <Item>xs=4</Item>
                    </Grid>

                    <Grid item xs={4}>
                        <Item>Mining stats1</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>Mining stats2</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>Mining stats3</Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>xs=8</Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>xs=8</Item>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
    );
}

export default show