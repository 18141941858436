export function formatSizeUnits(bytes: number, showZeros?: boolean) {
    let ret = ''
    if (bytes >= 1073741824 * 1024) {
        ret = (bytes / 1073741824 / 1024).toFixed(2) + " TB";
    } else if (bytes >= 1073741824) {
        ret = (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
        ret = (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
        ret = (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes > 1) {
        ret = bytes + " bytes";
    } else if (bytes === 1) {
        ret = bytes + " byte";
    } else {
        ret = showZeros ? "0 bytes" : "";
    }
    return ret;
}

export function FilAmount(amount: number): number {
    return amount / 1000000000000000000
}