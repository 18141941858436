enum ActorType {
    miner=100,
    actor,
    account,
    multisig,
    sys,
    registry,
    paym,
    none=-1,

}
export {ActorType}