import {
    Datagrid,
    List,
    MenuItemLink,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    useTranslate
} from 'react-admin';

import {Card} from "@mui/material";
import * as React from "react";
import {WidgetsOutlined} from "@mui/icons-material";
import {LAMenuItemProps} from "../../types";
import {ShortUUIDField} from "../../fields";

export const list = () => (
    <List >
        <Datagrid rowClick="show">
            {/*<TextField source="id" />*/}
            <ReferenceField source="epoch" reference="Epoch" link="show" />
            <ReferenceField source="miner_id" reference="Account" link="show" />
            {/*<TextField source="miner_id" />*/}
            <ShortUUIDField source="cid" label="CID#" side="both" />
        </Datagrid>
    </List>
);

export const show = () => (
        <Card sx={{ maxWidth: '50em' }}>
            <h2>Block</h2>
            <Show>
                <SimpleShowLayout >
                <TextField source="id" />
                <ReferenceField source="epoch" reference="Epoch" link="show"  />
                <ReferenceField source="miner_id" reference="Account"  link="show"/>
                <TextField source="cid"   />
                </SimpleShowLayout>
            </Show>
        </Card>
);

const LaMenuItem = (props: LAMenuItemProps ) => {
    const translate=useTranslate()
    return (
        <MenuItemLink
            to="/Block"
            state={{_scrollToTop: true}}
            primaryText={translate(`resources.blockchain.blocks`, {
                smart_count: 2,
            })}
            leftIcon={<WidgetsOutlined/>}
            dense={false}
        />
    );
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {list,show,icon: WidgetsOutlined,LaMenuItem}