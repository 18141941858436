import {DateField, useRecordContext} from "react-admin";
import * as React from "react";
import get from "lodash/get"
import PauseCircleOutlineTwoTone from "@mui/icons-material/PauseCircleOutlineTwoTone";
import PlayCircleFilledTwoTone from "@mui/icons-material/PlayCircleFilledTwoTone";
import PriorityHighTwoTone from "@mui/icons-material/ErrorOutlineTwoTone";
import TimerTwoTone from "@mui/icons-material/TimerTwoTone";
import CancelTwoTone from "@mui/icons-material/CancelTwoTone";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import CopyAllIcon from '@mui/icons-material/CopyAll';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import DeleteIcon from '@mui/icons-material/Delete';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';

import {Link} from 'react-router-dom';

import {Card, Table, TableBody, TableCell, TableHead, TableRow,} from '@mui/material';

import PropTypes from "prop-types";
import {Folder, SnippetFolder} from "@mui/icons-material";
import {FilAmount, formatSizeUnits} from "./utils/utils";

//import {Entity} from "./aot/types";
export const AddressField = (props: { source: string })=>{
    const record = useRecordContext();
    const fullAddress = record![props.source]
    const addr = fullAddress.substring(1,8)+'...'+fullAddress.substring(fullAddress.length-8)
    // const ret=<FunctionField  label="Address" render={ rec => }
    return <span title={fullAddress}>{addr}</span>
}


export const DealArrayField = (props: { source: string })=>{
    const record = useRecordContext();
    const deals = get(record,'Deals')
    const proposalCids = get(record,'DealProposals')

    return deals.map ((d:number,i:number)=>{
        if (proposalCids) {
            const cid = proposalCids[i]
            return <Link to={`/storage_deal/${cid}/show`}>{d} , </Link>
        }else
            return <span/>
    })
    //return <span ></span>
}

export const SectorExpirationField = (props: { source: string ,label?:string})=>{
    const record = useRecordContext();
    //let val=get(record,props.source)

    const expiration:Number=Number(get(record,'Expiration'))
    if (expiration===0)
        return null
    else
        return <DateField title={String(expiration)} source={props.source} options={{  year: 'numeric', month: 'short', day: 'numeric' }} />
}


export const JobFailureField = (props: { source: string ,label?:string})=>{
    const record = useRecordContext();
    let val=get(record,props.source)

    if (val)
        return <PriorityHighTwoTone style={{fill: "orangered"}}/>
    else
        return null
}

export const JobStateField = (props: { width?:string ,source: string ,label?:string})=>{
    const record = useRecordContext();

    let val=Number(get(record,props.source))

    const State=get(record,'State')
    const Failed=get(record,'Failed')
    const deals:Array<number>=get(record,'Deals')

    const hasDeals=deals.find((d:number) => { return d > 0})

    console.log({val,State,Failed,deals})
    if (State==='Proving'&& !hasDeals)
        return <Folder style={{fill: "grey"}}/>

    if (State==='Proving'&& hasDeals)
        return <SnippetFolder style={{fill: "grey"}}/>


    if (Failed)
        return <CancelTwoTone style={{fill: "red"}}/>


    if (State==='WaitSeed' || State==='TerminateWait' || State==='UpdateActivating'||State==='ReplicaUpdateWait')
        return <TimerTwoTone />

    if (State==='CommitAggregateWait' || State==='PreCommitBatchWait')
        return <MarkEmailReadIcon style={{fill: "darkgray"}}/>

    if (State==='SubmitCommitAggregate'||State==='SubmitPreCommitBatch' )
        return <ForwardToInboxIcon style={{fill: "orange"}}/>

    if (State==='Available')
        return <FolderOpenIcon />

    if (State==='Removed')
        return <DeleteIcon style={{fill: "darkgray"}}/>

    if (State==='Removing' || State==='Terminating' || State==='TerminateFinality')
        return <AutoDeleteIcon style={{fill: "darkgray"}}/>

    if (State==='WaitDeals'||State==='SnapDealsWaitDeals' )
        return <AppRegistrationIcon />

    if (State==='UpdateReplica' )
        return <CopyAllIcon />

    if (Number.isNaN(val)) return <HelpOutlineIcon/>

    if (Number(val)===0 )
        return  <PlayCircleFilledTwoTone  style={{fill: "green"}}/>
    else if (Number(val)===1 )
        return <PauseCircleOutlineTwoTone style={{fill: "gray"}}/>
    else
        return <div><PauseCircleOutlineTwoTone /></div>
}

export const TimestampField = (props: { source: string })=>{
    const record = useRecordContext();
    let val=get(record,props.source)
    const date=new Date(Number(val)*1000)

    // const ret=<FunctionField  label="Address" render={ rec => }
    return <span>{date.toISOString()}</span>
}

export const JobDurationField = (props: { source: string })=>{
    const record = useRecordContext();
    let val=get(record,props.source)

    let start=''
    if (val) {

        const diff =Date.now()-Date.parse(val)
        start = timeDiffStr(diff,true)
    }
    else
        start=''
    return <span>{start}</span>
}


function timeDiffStr(val: number,showSeconds?:boolean) {
    let distance = val

    const hours = Math.floor(distance / 3600000);
    distance -= hours * 3600000;
    const minutes = Math.floor(distance / 60000);
    distance -= minutes * 60000;
    const seconds = Math.floor(distance / 1000);
    return `${hours}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
    // const duration_minutes = Math.floor(val / 60)
    // const duration_seconds = val-duration_minutes*60
    // const hours = Math.floor(duration_minutes / 60)
    // const minutes = duration_minutes % 60
    //
    // const minutesStr = ('0' + String(minutes)).slice(-2)
    // const secondsStr = showSeconds? ('0' + String(duration_seconds)).slice(-2) +'s': ''
    // const hoursStr= hours==0 ? '': `${hours}h`
    // const ret = hours == 0 && minutes == 0 && duration_seconds===0 ? '' : `${hoursStr}${minutesStr}m${secondsStr}`

}

export const ObjectAsTable  = (props: { obj: any,excludeFields?:Array<string> })=>{


    const data=Object.keys(props.obj).map ( field =>{
        let value=props.obj[field]
        if (typeof value==='object')
            value=JSON.stringify(value)
        if (props.excludeFields && props.excludeFields.includes(field))
            value=undefined
        return { field, value}
    }).filter(v => v.value!==undefined)
    return (
        <Card>
            <Table sx={{ padding: 2 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Value</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(item => (
                        <TableRow key={item.field}>
                            <TableCell>{item.field}</TableCell>
                            <TableCell>{item.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    )}


export const RecordAsTableField  = (props: { source: string,showSeconds?:boolean,excludeFields?:Array<string> })=>{
    const record=useRecordContext()
    const data=Object.keys(record!).map ( field =>{
        let value=record![field]
        if (typeof value==='object')
            value=JSON.stringify(value)
        if (props.excludeFields?.includes(field))
            value=undefined
        return { field, value}
    }).filter(v => v.value!==undefined)
    return (
        <Card>
            <Table sx={{ padding: 2 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Value</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(item => (
                        <TableRow key={item.field}>
                            <TableCell>{item.field}</TableCell>
                            <TableCell>{item.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    )}



export const TimeDiffField = (props: { source: string,showSeconds?:boolean })=>{
    const record = useRecordContext();
    let val=get(record,props.source)*1000
    const ret = timeDiffStr(val,props.showSeconds);


    return <span>{ret}</span>
}

export const SectorLogKindField = (props: { source: string })=>{
    const record = useRecordContext();
    let val=String(get(record,props.source))

    const ret=val.replace('event;sealing.','')
    // const ret=<FunctionField  label="Address" render={ rec => }
    return <span title={val}>{ret}</span>
}

export const ShortTextField = (props: { source: string,size?:number })=>{
    const record = useRecordContext();

    let size=props.size
    if (!size) size=30
    const text=String(record![props.source])
    //console.log({text: text.length})
    const short = text.substring(0,size-1)+'...'


    // const ret=<FunctionField  label="Address" render={ rec => }
    return <span title={text}>{short}</span>
}


// export const ShortUUIDFieldEx = <T extends Entity,>(props: { source1: keyof T ,label?:string,charQty?:number, side?:'left'|'right'|'both'})=>{
//     return <ShortUUIDField source={String(props.source1)} {...props}/>
// }

export const ShortUUIDField = (props: { source: string ,label?:string,charQty?:number, side?:'left'|'right'|'both'})=>{

    let {charQty,side}=props
    if (!charQty)
        charQty=9
    if (!side)
        side='left'

    charQty=Math.abs(charQty)

    const record = useRecordContext();
    const fullAddress = get(record,props.source) || ""
    let addr=''
    switch (side) {
        case 'left' : addr = fullAddress.substring(0, charQty - 1);break;
        case 'right': addr = fullAddress.substring(fullAddress.length - charQty);break
        case 'both' : addr = fullAddress.substring(0, charQty - 1)+'...'+fullAddress.substring(fullAddress.length - charQty-1);break
        default:
            addr=fullAddress
    }

    // const ret=<FunctionField  label="Address" render={ rec => }
    return <span title={fullAddress}>{addr}</span>
}


export const FilAmountField = (props: { source: string,showZeros?:boolean})=>{
    const record = useRecordContext();
    //const size=record[props.source]
    let val=get(record,props.source)

    //val=Number(val)

    const filAmount=String(FilAmount(val).toFixed(5)) + ' Fil'

    if (val==='0'){
        return null
    }else
        return <span>{filAmount}</span>
}

FilAmountField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};


export const FileSizeField = (props: { source: string,label?:string,showZeros?:boolean})=>{
    const record = useRecordContext();
    //const size=record[props.source]
    const size=get(record,props.source)
    const sizeStr=formatSizeUnits(size,props.showZeros)
    // const ret=<FunctionField  label="Address" render={ rec => }
    return <span>{sizeStr}</span>
}
