import Toc from '@mui/icons-material/Toc';
import {LAMenuItemProps} from "../../types";
import {MenuItemLink, useTranslate} from "react-admin";
import * as React from "react";


const list=()=>(
    <h2>Sp Ledger Entry not implemented </h2>
)

const LaMenuItem = (props: LAMenuItemProps ) => {
    const translate=useTranslate()
    return (
        <MenuItemLink
            to="/SpLedgerEntry"
            state={{_scrollToTop: true}}
            primaryText={translate(`resources.entries.miner`, {
                smart_count: 2,
            })}
            leftIcon={<Toc/>}
            dense={false}
        />
    );
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {list,LaMenuItem}