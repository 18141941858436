import {
    BooleanField,
    DatagridConfigurable,
    DateField,
    Edit,
    ExportButton,
    List,
    MenuItemLink,
    SelectColumnsButton,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TopToolbar,
    useTranslate
} from 'react-admin';
import {FolderCopyOutlined} from "@mui/icons-material"
import {Box, Card} from "@mui/material";
import {LAMenuItemProps} from "../../types";
import * as React from "react";

import FilterAsidePanel from './aside'
import {FilAmountField} from "../../fields";

const LaMenuItem = (props: LAMenuItemProps ) => {
    const translate=useTranslate()


    return (
        <MenuItemLink
            to="/Sector"
            state={{_scrollToTop: true}}
            primaryText={translate(`resources.sectors.name`, {
                smart_count: 2,
            })}
            leftIcon={<FolderCopyOutlined/>}
            dense={false}
        />
    );
};

const ListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <ExportButton />
    </TopToolbar>
);

export const list = () => (
    <List
        aside={<FilterAsidePanel />}
        actions={<ListActions />}
    >
        <DatagridConfigurable rowClick="show">
            <TextField source="id" />
            {/*<DateField source="time" showTime={true} />*/}
            <TextField source="miner_id" />
            <TextField source="sector_id" />
            {/*<TextField source="partition_id" />*/}
            <TextField source="deadline_id" />
            <TextField source="start_epoch" />
            <TextField source="expiration_epoch" />
            <BooleanField source="hasDeals" />
            <BooleanField source="live" />
            <BooleanField source="active" />
            <BooleanField source="recovering" />
            <BooleanField source="faulty" />
            <TextField source="sectorState" />
            <FilAmountField source="Amount"/>
            <TextField source="QaP"/>
        </DatagridConfigurable>
    </List>
);

export const show = () => (

    <Card sx={{ maxWidth: '50em' }} >
        <h2>Epoch</h2>
        <Show>
            <SimpleShowLayout >

                <TextField source="id" />
                <DateField source="time" />
                {/*<TextField source="blocks" />*/}
                {/*<TextField source="totalMsg" />*/}
                <TextField source="baseFee" />

            </SimpleShowLayout>
        </Show>
    </Card>

);

export const edit = () => (
    <Box sx={{ maxWidth: '50em' }}>
        <Card>
            <Edit >
                <SimpleForm>
                    <TextField source="id" />
                    <DateField source="time" />
                    {/*<TextField source="blocks" />*/}
                    {/*<TextField source="totalMsg" />*/}
                    <TextField source="baseFee" />
                </SimpleForm>
            </Edit>
        </Card>
    </Box>

);



// eslint-disable-next-line import/no-anonymous-default-export
export default {list,show, icon:FolderCopyOutlined ,LaMenuItem}
//export default {list,LaMenuItem}