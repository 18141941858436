import {
    BooleanField,
    DatagridConfigurable,
    ListBase,
    NumberField,
    Pagination,
    SimpleShowLayout,
    TextField,
    useRecordContext
} from "react-admin";
import {Grid, Paper} from "@mui/material";
import {styled} from "@mui/material/styles";
import {FilAmountField, FileSizeField} from "../../widget";
import * as React from "react";
//1A2027
const Item = styled(Paper)(({ style,theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:'100%'

}));
export const Miner_dashboard = () => {
    const acc_rec=useRecordContext()
    let actor_id=Number(acc_rec!.id)
    if (actor_id === 1000)
       actor_id=99608

    return (

        <SimpleShowLayout>
            <p>Miner dashboard</p>
            <TextField source="id" label={false}/>
            <Grid container>

                {/*<ReferenceField source="tenant_id" reference="Tenant" />*/}
                {/*<TextField source="Address" />*/}

                {/*<ReferenceField source="Owner" reference="Account" />*/}
                {/*<ReferenceField source="Worker" reference="Account" />*/}
                {/*<ReferenceField source="Beneficiary" reference="Account" />*/}
                {/*<ReferenceArrayField source="ControlAddresses" reference="Account"/>*/}
                {/*<TextField source="name" />*/}
                {/*<TextField source="descr" />*/}
                {/*<TextField source="robust_addr" />*/}
                {/*<TextField source="sealProof" />*/}
                {/*<SelectField source="account_type" choices={[*/}
                {/*    { id: 0, name: 'Miner' },*/}
                {/*    { id: 1, name: 'Account 1' },*/}
                {/*    { id: 2, name: 'Account 2' },*/}
                {/*    { id: 3, name: 'Account 3' },*/}
                {/*]} />*/}

            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Item>
                        {/*<h3>Balance data:</h3>*/}
                        <FilAmountField source="Balance" />
                        <p/>
                        <FilAmountField source="AvailableBalance"/>
                        <p/>
                        <FilAmountField source="FeeDebt"/>
                        <p/>
                        <FilAmountField source="Pledge"/>
                        <p/>
                        <FilAmountField source="PrecommitDeposits"/>
                        <p/>
                        <FilAmountField source="VestingFunds"/>
                        <p/>
                        <FilAmountField source="Escrow"/>
                        <p/>
                        <FilAmountField source="MarketLocked"/>

                    </Item>
                </Grid>
                <Grid item xs={3}>
                    <Item>
                        <h3>Power data</h3>
                        <FileSizeField source="qap"/>
                        <p/>
                        <FileSizeField source="rbp"/>
                    </Item>
                </Grid>
                <Grid item>
                    <ListBase resource="MinerPartition" filter={{miner_id: actor_id}}

                        // aside={<FilterAsidePanel />}
                        // actions={<ListActions />}
                    >
                        <DatagridConfigurable rowClick="show">
                            <TextField source="id"/>
                            {/*<DateField source="time" showTime={true} />*/}
                            {/*<TextField source="miner_id"/>*/}
                            {/*<TextField source="deadline_id"/>*/}
                            {/*<TextField source="partition_id"/>*/}
                            {/*<TextField source="live"/>*/}
                            <TextField source="active"/>
                            <TextField source="recovering"/>
                            <BooleanField source="wdpost"/>
                            <NumberField source="deals"/>
                            <NumberField source="faulty"/>
                            <TextField source="qap"/>
                            <TextField source="rbp"/>
                            <TextField source="state"/>
                            <FilAmountField source="pledge"/>

                        </DatagridConfigurable>
                        <Pagination/>
                    </ListBase>
                </Grid>

            </Grid>
        </SimpleShowLayout>

    );
}

export default Miner_dashboard