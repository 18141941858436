import * as React from 'react';
import get from "lodash/get"
import {useRecordContext,} from 'react-admin';
import {FilAmount, formatSizeUnits} from "../utils/utils";

export const FilAmountField = (props: { source: string,showZeros?:boolean})=>{
    const record = useRecordContext();
    //const size=record[props.source]
    let val=get(record,props.source)
    //val=Number(val)
    const filAmount=String(FilAmount(val).toFixed(5)) + ' Fil'
    if (val==='0'){
        return null
    }else
        return <span>{filAmount}</span>
}

export const FileSizeField = (props: { source: string,label?:string,showZeros?:boolean})=>{
    const record = useRecordContext();
    //const size=record[props.source]
    const size=get(record,props.source)
    const sizeStr=formatSizeUnits(size,props.showZeros)
    // const ret=<FunctionField  label="Address" render={ rec => }
    return <span>{sizeStr}</span>
}